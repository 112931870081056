import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

import SEO from "../components/seo"

import ApremiaLogoImage from "../images/logo.svg"
import ErrorImageSrc from "../images/error-404.svg"

const ApremiaLogo = styled.svg`
  height: 32px;
`

const ErrorImage = styled.svg`
  @media (max-width: 768px) {
    width: 100%;
  }
`

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t("page_not_found")} />

      <Container>
        <Row>
          <Col xs="12" md="7" className="mx-auto text-center">
            <ApremiaLogo as={ApremiaLogoImage} />

            <hr className="my-4 my-md-5" />

            <ErrorImage as={ErrorImageSrc} />

            <h1 className="my-5">{t("not_found_message")}</h1>

            <Link to="/" className="btn btn-primary">
              {t("not_found_action")}
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
